import React, { useEffect, useState } from 'react';

//Models
import { VendorInterface } from '../../shared/types';

// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';

import { getVendors, getVendorIndustries } from 'Api';
import { Button, Card, Col, Row } from 'react-bootstrap';

//Multiselect dropdown
import { OnChangeValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import debugLog from 'lib/debug';

export const SelectVendorPage: React.FC = () => {
  const [vendors, setVendors] = useState<VendorInterface[]>([]);

  const selectedVendors = useLiveQuery(() => dexieDB.vendors.toArray());

  useEffect(() => {
    const getVendorsHandler = async (): Promise<void> => {
      const response = await getVendors();

      debugLog(response, { enable: false });
      if (response) {
        setVendors(response.data);
      }
    };

    getVendorsHandler();
  }, []);

  const isSelected = (vendorId: number): boolean => {
    if (selectedVendors) {
      return selectedVendors.some((vendor) => vendor.vendors_id === vendorId);
    }
    console.log('vendors', vendors);

    return false;
  };

  const handleVendorSelect = async (vendorId: number): Promise<void> => {
    if (!selectedVendors) return;

    if (isSelected(vendorId) === true) {
      await dexieDB.vendors.delete(vendorId);
    } else {
      await dexieDB.vendors.add({ vendors_id: vendorId });
    }
  };

  const handleCategoriesLoad = async (input: string): Promise<Pick<VendorInterface, 'vendors_industry'>[]> => {
    const response = await getVendorIndustries(input);

    debugLog(response, { enable: false });
    if (response) {
      return response.data as Pick<VendorInterface, 'vendors_industry'>[];
    } else {
      return [];
    }
  };

  const handleIndustryMultiSelect = async (newValue: OnChangeValue<Pick<VendorInterface, 'vendors_industry'>, true>): Promise<void> => {
    const response = await getVendors(newValue as Pick<VendorInterface, 'vendors_industry'>[]);

    debugLog(response, { enable: false });
    if (response) {
      setVendors(response.data);
    }
  };

  return (
    <>
      <Row className="heading-intro">
        <Col>
          <h1>Forhandler</h1>
          <h5>Vælg den forhandler du vil importere produkter fra</h5>
        </Col>
      </Row>
      <Row className="vendor">
        <Col sm={12}>
          <AsyncSelect
            classNamePrefix="vendor-select"
            loadOptions={handleCategoriesLoad}
            defaultOptions
            isMulti={true}
            isSearchable={true}
            placeholder="Vælg branche"
            onChange={handleIndustryMultiSelect}
            getOptionValue={(option: Pick<VendorInterface, 'vendors_industry'>) => option.vendors_industry}
            getOptionLabel={(option: Pick<VendorInterface, 'vendors_industry'>) => `${option.vendors_industry}`}
          />
        </Col>

        {vendors.map((vendor) => (
          <Col key={vendor.vendors_id} sm={6} lg={4} xl={3}>
            <Card className="vendor-card" onClick={() => handleVendorSelect(vendor.vendors_id ? vendor.vendors_id : 0)}>
              <Card.Img variant="top" src={vendor.vendors_image_url} alt="forhandler billede" />
              <Card.Body>
                <Card.Title>{vendor.vendors_name}</Card.Title>
                <Button className={vendor.vendors_id && isSelected(vendor.vendors_id) === true ? 'deselect' : ''}>
                  {vendor.vendors_id && isSelected(vendor.vendors_id) === true ? 'Fravælg' : 'Vælg'}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
