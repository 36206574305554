import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
//Multiselect dropdown
import Select, { OnChangeValue } from 'react-select';

import { SimpleModalProps } from './types';
import { ProductInterface, CategoryInterface, ExtraAttributes } from '../../../pages/import-products/shared/types';
import { dexieDB } from '../../../pages/import-products/shared/db';

const SimpleProductModal: React.FC<React.PropsWithChildren<SimpleModalProps>> = (props) => {
  const [attributes, setAttributes] = useState<ExtraAttributes[]>([]);
  const [productStock, setProductStock] = useState<string>(
    props.productUnderEdit && props.productUnderEdit.vendor_products_stock ? props.productUnderEdit.vendor_products_stock.toString() : '',
  );

  const updateAttributes = async () => {
    const attributeData: ExtraAttributes[] = [];

    if (props.productUnderEdit) {
      if (props.productUnderEdit.vendor_products_properties && typeof props.productUnderEdit.vendor_products_properties === 'string') {
        const properties = JSON.parse(props.productUnderEdit.vendor_products_properties);

        for await (const property of properties) {
          for await (const [key, value] of Object.entries(property)) {
            attributeData.push({
              name: key,
              value: value as string,
            });
          }
        }
      }
    }

    setAttributes(attributeData);
  };

  useEffect(() => {
    updateAttributes();
  }, [props.productUnderEdit, props.isSimpleModalOpen]);

  useEffect(() => {
    if (!props.productUnderEdit) {
      return;
    }

    let ProductStockCount = 0;

    if (productStock) {
      ProductStockCount = Number(productStock);
    }

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      ['vendor_products_stock']: ProductStockCount,
    });
  }, [productStock]);

  useEffect(() => {
    if (!props.productUnderEdit) {
      return;
    }

    setProductStock(`${Number(props.productUnderEdit.vendor_products_stock)}`);
  }, [props.productUnderEdit]);

  const handleProductSave = async (product: ProductInterface | null | undefined): Promise<void> => {
    if (!product || !product.vendor_products_id) {
      return;
    }

    const product_variations_properties = attributes.map((attribute) => {
      return { [attribute.name]: attribute.value };
    });

    const updatedProduct = {
      ...product,
      vendor_products_properties: JSON.stringify(product_variations_properties),
    };

    await dexieDB.editedProducts.update(product.vendor_products_id, updatedProduct);
    props.setIsSimpleModalOpen(false);
  };

  const handleProductValueChanged = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>): void => {
    if (!props.productUnderEdit) {
      return;
    }

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      [event.target.id]: event.target.value,
    });
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    if (!props.productUnderEdit) {
      return;
    }

    props.setProductUnderEdit({
      ...props.productUnderEdit,
      vendor_products_category: newValue as CategoryInterface[],
    });
  };

  const handleAddAttribute = () => {
    const newAttribute = { name: '', value: '' };
    setAttributes((currentAttributes) => [...currentAttributes, newAttribute]);
  };

  const handleRemoveAttribute = (index: number) => {
    setAttributes((currentAttributes) => currentAttributes.filter((_, currentIndex) => currentIndex !== index));
  };

  const handleAttributeChange = (index: number, key: keyof ExtraAttributes, value: string) => {
    setAttributes((currentAttributes) => currentAttributes.map((attr, i) => (i === index ? { ...attr, [key]: value } : attr)));
  };

  const getProductStatus = (): string => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_status) {
      return 'draft';
    }

    return props.productUnderEdit.vendor_products_status;
  };

  const getProductSalesPrice = (): number => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_sales_price) {
      return 0;
    }

    return props.productUnderEdit.vendor_products_sales_price;
  };

  const getProductCostPrice = (): number => {
    if (!props.productUnderEdit || !props.productUnderEdit.vendor_products_cost_price) {
      return 0;
    }

    return props.productUnderEdit.vendor_products_cost_price;
  };

  const getStockStatus = (): 'instock' | 'outofstock' => {
    if (props.productUnderEdit?.vendor_products_stock === 0) {
      return 'outofstock';
    } else {
      return 'instock';
    }
  };

  return (
    <Modal show={props.isSimpleModalOpen} className="edit-product-modal" backdrop="static" keyboard={false}>
      <Modal.Header className="justify-content-between">
        <Modal.Title className="edit-product-modal-header">
          <h1>Rediger produkt</h1>
          <h2>Indsæt dine egne værdier i felterne nedenfor</h2>
        </Modal.Title>
        <Button className="edit-product-modal-close" variant="secondary" onClick={() => props.setIsSimpleModalOpen(false)}>
          <img src="/fa/svgs/solid/xmark.svg" loading="lazy" alt="kryds" />
        </Button>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Row className="d-flex">
            <Col className="edit-product-modal-nav-btn">
              <button
                className={!props.prevAndNext.prevProduct ? 'hidden' : ''}
                type="button"
                onClick={() => props.setProductUnderEdit(props.prevAndNext.prevProduct)}
              >
                <img src="/fa/svgs/solid/chevron-left.svg" loading="lazy" alt="pil venstre" />
              </button>
            </Col>
            <Col>
              <Row className="edit-product-modal-body">
                <Col>
                  {props.productUnderEdit && props.productUnderEdit.vendor_products_image && props.productUnderEdit.vendor_products_image.length !== 0 ? (
                    <img className="edit-product-modal-body-image" src={props.productUnderEdit.vendor_products_image} alt="produkt billede" loading="lazy" />
                  ) : null}
                </Col>
                <Col>
                  <Row>
                    <Form.Group controlId={'vendors_name'} className="edit-product-modal-body-input">
                      <Form.Label>Forhandler</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Forhandler"
                        value={props.productUnderEdit?.vendors_name}
                        onChange={handleProductValueChanged}
                        disabled
                      />
                    </Form.Group>
                  </Row>

                  {props.productUnderEdit?.product_variations?.length === 0 && (
                    <Row className="gap-2">
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_sku'} className="edit-product-modal-body-input">
                            <Form.Label>Varenummer / SKU</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Varenummer"
                              value={props.productUnderEdit?.vendor_products_sku}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_ean'} className="edit-product-modal-body-input">
                            <Form.Label>Stregkode / EAN</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Stregkode / EAN"
                              value={props.productUnderEdit?.vendor_products_ean}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Form.Group controlId={'vendor_products_name'} className="edit-product-modal-body-input">
                      <Form.Label>Produktnavn</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast produktnavn"
                        value={props.productUnderEdit?.vendor_products_name}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group controlId={'vendor_products_slug'} className="edit-product-modal-body-input">
                      <Form.Label>URL / Slug</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Indtast produkt slug"
                        value={props.productUnderEdit?.vendor_products_slug}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>

                  {/* Price */}
                  {props.productUnderEdit?.product_variations?.length === 0 && (
                    <Row className="gap-2">
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_price'} className="edit-product-modal-body-input">
                            <Form.Label>Vejl. pris </Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={50}
                              placeholder="Indtast vejledende pris"
                              value={props.productUnderEdit?.vendor_products_price}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_sales_price'} className="edit-product-modal-body-input">
                            <Form.Label>Tilbudspris</Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={50}
                              placeholder="Indtast tilbudspris"
                              value={getProductSalesPrice()}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_cost_price'} className="edit-product-modal-body-input">
                            <Form.Label>Kostpris</Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={50}
                              placeholder="Indtast kostpris"
                              value={getProductCostPrice()}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                      <Form.Text className="text-muted m-0">* Priser opgivet i € er ganget med kurs 7,5</Form.Text>
                    </Row>
                  )}

                  {/* Status + Stock + Weight */}
                  <Row className="gap-2">
                    {props.productUnderEdit?.product_variations?.length === 0 && (
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_stock'} className="edit-product-modal-body-input">
                            <Form.Label>Lager</Form.Label>
                            <div className="d-flex stock-input">
                              <span className={getStockStatus() === 'instock' ? 'in-stock' : 'out-of-stock'}></span>
                              <Form.Control
                                type="number"
                                min={0}
                                placeholder="Indtast lagerbeholdning"
                                value={productStock}
                                onChange={(event) => setProductStock(event.target.value)}
                              />
                            </div>
                          </Form.Group>
                        </Row>
                      </Col>
                    )}
                    <Col>
                      <Row>
                        <Form.Group controlId={'vendor_products_status'} className="edit-product-modal-body-input">
                          <Form.Label>Status</Form.Label>
                          <Form.Select aria-label="status" value={getProductStatus()} onChange={handleProductValueChanged}>
                            <option value="draft">Kladde</option>
                            <option value="publish">Udgiv</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                    </Col>
                    {props.productUnderEdit?.product_variations?.length === 0 && (
                      <Col>
                        <Row>
                          <Form.Group controlId={'vendor_products_weight'} className="edit-product-modal-body-input">
                            <Form.Label>Vægt</Form.Label>
                            <Form.Control
                              type="number"
                              min={0}
                              step={5}
                              placeholder="Indtast vægt"
                              value={props.productUnderEdit?.vendor_products_weight}
                              onChange={handleProductValueChanged}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                    )}
                  </Row>

                  {/* Description */}
                  <Row>
                    <Form.Group controlId={'vendor_products_excerpt'} className="edit-product-modal-body-input">
                      <Form.Label>Kort beskrivelse</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Indtast kort beskrivelse"
                        value={props.productUnderEdit?.vendor_products_excerpt}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group controlId={'vendor_products_content'} className="edit-product-modal-body-input">
                      <Form.Label>Beskrivelse</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Indtast beskrivelse"
                        value={props.productUnderEdit?.vendor_products_content}
                        onChange={handleProductValueChanged}
                      />
                    </Form.Group>
                  </Row>

                  {/* Category */}
                  <Row>
                    <Form.Group controlId={'vendor_products_category'} className="edit-product-modal-body-input">
                      <Form.Label>Kategori</Form.Label>
                      <Form.Text className="d-block text-muted">Hvis en kategori ikke findes, skal du oprette den i webshoppen</Form.Text>
                      <Select
                        id="vendor_products_category"
                        className="modal-select"
                        classNamePrefix="modal-select"
                        options={props.webshopCategories}
                        placeholder="Vælg kategori"
                        isSearchable={true}
                        isMulti={true}
                        getOptionValue={(option: CategoryInterface) => option.id}
                        getOptionLabel={(option: CategoryInterface) => `${option.name}`}
                        onChange={handleEditCategories}
                        noOptionsMessage={() => `${'Ingen kategorier'}`}
                        defaultValue={props.productUnderEdit?.vendor_products_category as CategoryInterface[]}
                      />
                    </Form.Group>
                  </Row>

                  {/* Attributes */}
                  {props.productUnderEdit?.product_variations?.length === 0 && (
                    <>
                      <Row className="edit-product-modal-body-input">
                        <Form.Label className="p-0">Opret Attributter</Form.Label>
                        {attributes.map((attribute, index) => (
                          <Row key={index} className="gap-2">
                            <Col className="p-0">
                              <Form.Group controlId={`attribute-name-${index}`} className="edit-product-modal-body-input">
                                <Form.Control
                                  type="text"
                                  placeholder="Indtast navn"
                                  value={attribute.name}
                                  onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                                />
                              </Form.Group>
                            </Col>
                            <Col className="p-0">
                              <Form.Group controlId={`attribute-value-${index}`} className="edit-product-modal-body-input">
                                <Form.Control
                                  type="text"
                                  placeholder="Indtast værdi"
                                  value={attribute.value}
                                  onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                                />
                              </Form.Group>
                            </Col>
                            <div className="edit-product-modal-remove">
                              <Button onClick={() => handleRemoveAttribute(index)}>
                                <img src="/fa/svgs/solid/xmark.svg" loading="lazy" alt="kryds" />
                              </Button>
                            </div>
                          </Row>
                        ))}

                        <Row>
                          <Col xs={12} className="d-flex justify-content-left edit-product-modal-body-input">
                            <Button onClick={handleAddAttribute}>Tilføj attribut</Button>
                          </Col>
                        </Row>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col className="edit-product-modal-nav-btn">
              <button
                className={!props.prevAndNext.nextProduct ? 'hidden' : ''}
                type="button"
                onClick={() => props.setProductUnderEdit(props.prevAndNext.nextProduct)}
              >
                <img src="/fa/svgs/solid/chevron-right.svg" loading="lazy" alt="pil højre" />
              </button>
            </Col>
          </Row>
          <Row>
            <Col className="edit-product-modal-body-buttons">
              <Button className="button-orange" onClick={() => props.setIsSimpleModalOpen(false)}>
                Afbryd
              </Button>
              <Button onClick={() => handleProductSave(props.productUnderEdit)}>Gem</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default SimpleProductModal;
