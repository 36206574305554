import { CustomToastProps } from './types';
import React, { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';

const CustomToast: React.FC<CustomToastProps> = ({ show, title, message, imgSrc, timeAgo, type, onClose, duration }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [show, onClose, duration]);

  const headerClass = type === 'success' ? 'toast-success' : 'toast-error';

  return (
    <Toast show={show} onClose={onClose}>
      <Toast.Header className={headerClass}>
        {imgSrc && <img src={imgSrc} className="rounded me-2" alt="Toast Image" loading="lazy" />}
        <strong className="me-auto">{title}</strong>
        {timeAgo && <small>{timeAgo}</small>}
      </Toast.Header>
      <Toast.Body className="toast-background">{message}</Toast.Body>
    </Toast>
  );
};

export default CustomToast;
