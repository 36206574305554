import React, { useEffect, useState } from 'react';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';
//Models
import { ProductInterface, CategoryInterface } from '../../shared/types';
import { Button, Col, Row, Table } from 'react-bootstrap';
//API
import { getWebshopProductCategories } from 'Api';
//Components
import { SimpleEditProducts, VariableEditProducts, SimpleProductModal, VariationProductModal } from 'components/products';
import { useSelectedProducts } from 'contexts/selected-products';
import CustomToast from 'components/toast';
import Search from 'components/search';

export const EditProductsPage: React.FC = () => {
  const [editedProducts, setEditedProducts] = useState<ProductInterface[]>([]);
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [productUnderEdit, setProductUnderEdit] = useState<ProductInterface | null>();
  const [variationId, setVariationId] = useState<number>(0);
  const [webshopCategories, setWebshopCategories] = useState<CategoryInterface[]>([]);
  const [sortField, setSortField] = useState<keyof ProductInterface | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [categorySearchTerm, setCategorySearchTerm] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [currentPage] = useState<number>(1);
  const [resultsPerPage] = useState<number>(20);
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [visibleCount, setVisibleCount] = useState<number>(20);
  const [massedit, setMassedit] = useState<boolean>(false);
  const [markAll, setMarkAll] = useState<boolean>(false);
  const [editedCount, setEditedCount] = useState<number>(0);
  const [categoryTree, setCategoryTree] = useState<CategoryInterface[]>([]);

  const [vejlPrice, setVejlPrice] = useState<string>('');
  const [discountPrice, setDiscountPrice] = useState<string>('');
  const [costPrice, setCostPrice] = useState<string>('');
  const [discount, setDiscount] = useState<string>('');
  const [discountType, setDiscountType] = useState<string>('');
  const [discountCost, setDiscountCost] = useState<string>('');
  const [discountCostType, setDiscountCostType] = useState<string>('');
  const [discountVejl, setDiscountVejl] = useState<string>('');
  const [discountVejlType, setDiscountVejlType] = useState<string>('');
  const [stock, setStock] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [categorySelected, setCategorySelected] = useState<CategoryInterface[]>([]);
  const [categoryShared, setCategoryShared] = useState<CategoryInterface[]>([]);

  const [toastActive, setToastActive] = useState(false);

  const editedProductsCount = useLiveQuery(() => dexieDB.editedProducts.count());
  const allEditedProducts = useLiveQuery(() => {
    return dexieDB.editedProducts.toArray().then((products) => {
      if (sortField === 'vendor_products_sku') {
        products.sort((a, b) => {
          const skuA = a.product_variations?.[0]?.vendor_product_variations_sku ?? a.vendor_products_sku;
          const skuB = b.product_variations?.[0]?.vendor_product_variations_sku ?? b.vendor_products_sku;

          if (sortOrder === 'asc') {
            return skuA.localeCompare(skuB);
          } else {
            return skuB.localeCompare(skuA);
          }
        });
      } else if (sortField) {
        products.sort((a, b) => {
          if (sortOrder === 'asc') {
            return String(a[sortField]).localeCompare(String(b[sortField]));
          } else {
            return String(b[sortField]).localeCompare(String(a[sortField]));
          }
        });
      }

      return products;
    });
  }, [sortField, sortOrder]);

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field as keyof ProductInterface);
      setSortOrder('asc');
    }

    localStorage.setItem('editSortField', field);
    localStorage.setItem('editSortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    const savedSortField = localStorage.getItem('editSortField');
    const savedSortOrder = localStorage.getItem('editSortOrder');

    if (savedSortField) {
      setSortField(savedSortField as keyof ProductInterface);
    }

    if (savedSortOrder) {
      setSortOrder(savedSortOrder as 'asc' | 'desc');
    }
  }, []);

  useEffect(() => {
    if (!allEditedProducts) {
      return;
    }

    setEditedProducts(allEditedProducts);
  }, [allEditedProducts, currentPage, resultsPerPage]);

  useEffect(() => {
    let isMounted = true;

    const getWebshopProductCategoriesHandler = async (): Promise<void> => {
      setIsLoadingCategories(true);
      const response = await getWebshopProductCategories();

      if (isMounted) {
        if (response) {
          setWebshopCategories(response.data);
        }
      }

      setIsLoadingCategories(false);
    };

    getWebshopProductCategoriesHandler();

    return () => {
      isMounted = false;
    };
  }, []);

  // Håndter kategori på masseredigering
  useEffect(() => {
    setCategoryTree(categoryNesting(webshopCategories));

    if (categorySearchTerm) {
      const matchesSearchTerm = webshopCategories.filter((category) => {
        return category.name?.toLowerCase().includes(categorySearchTerm.toLowerCase());
      });

      setCategoryTree(matchesSearchTerm);
    }
  }, [webshopCategories, categorySearchTerm]);

  // Filtrerer både på kategori og søgning
  useEffect(() => {
    if (!allEditedProducts) return;

    const filteredProducts = allEditedProducts.filter((product) => {
      const matchesSearchTerm = searchTerm
        ? product.vendor_products_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_ean?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.vendor_products_price?.toString().includes(searchTerm.toLowerCase())
        : true;

      const matchesCategory = category
        ? Array.isArray(product.vendor_products_category)
          ? product.vendor_products_category.some((cat) => {
              if (cat && typeof cat === 'object' && cat.id) {
                return parseInt(`${cat.id}`) === parseInt(`${category}`);
              }

              return false;
            })
          : product.vendor_products_category
          ? product.vendor_products_category.includes(category)
          : false
        : true;

      return matchesSearchTerm && matchesCategory;
    });

    // Opdaterer state med de produkter, som skal vises på den nuværende side
    setEditedProducts(filteredProducts);
  }, [allEditedProducts, currentPage, resultsPerPage, searchTerm, category]);

  useEffect(() => {
    if (selectedProducts) {
      const categoryCount: Record<string, { category: CategoryInterface; count: number }> = {};

      selectedProducts.forEach((product) => {
        if (Array.isArray(product.vendor_products_category)) {
          (product.vendor_products_category as CategoryInterface[]).forEach((category) => {
            if (categoryCount[category.id]) {
              categoryCount[category.id].count += 1;
            } else {
              categoryCount[category.id] = { category, count: 1 };
            }
          });
        }
      });

      const sharedCategories = Object.values(categoryCount)
        .filter((entry) => entry.count === selectedProducts.length)
        .map((entry) => entry.category);
      setCategoryShared(sharedCategories);

      const selectedCategories = Object.values(categoryCount).map((entry) => entry.category);
      setCategorySelected(selectedCategories);
    }
  }, [selectedProducts]);

  function clearAll() {
    setVejlPrice('');
    setDiscountVejl('');
    setDiscountVejlType('');
    setDiscountPrice('');
    setDiscount('');
    setDiscountType('');
    setCostPrice('');
    setDiscountCost('');
    setDiscountCostType('');
    setStock('');
    setStatus('');
    setSearchTerm('');
    setCategorySearchTerm('');
    setCategory('');
    setCategorySelected([]);
    setSelectedProducts([]);
    setMarkAll(false);
  }

  function handleCategoryChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setCategory(event.target.value);
  }

  const handleCategorySelected = (category: CategoryInterface) => {
    setSelectedProducts((prevSelectedProducts) => {
      const isCategoryShared = prevSelectedProducts.every(
        (product) =>
          Array.isArray(product.vendor_products_category) &&
          (product.vendor_products_category as CategoryInterface[]).some((productCategory) => productCategory.id === category.id),
      );

      const isCategoryPartial =
        prevSelectedProducts.some(
          (product) =>
            Array.isArray(product.vendor_products_category) &&
            (product.vendor_products_category as CategoryInterface[]).some((productCategory) => productCategory.id === category.id),
        ) && !isCategoryShared;

      const updatedProducts = prevSelectedProducts.map((product) => {
        let categories = product.vendor_products_category;

        if (!Array.isArray(categories)) {
          categories = typeof categories === 'string' ? [categories] : [];
        }

        let updatedCategories = (categories as CategoryInterface[]).filter((productCategory) =>
          webshopCategories.some((webshopCategory) => webshopCategory.id === productCategory.id),
        );

        updatedCategories =
          isCategoryShared || isCategoryPartial
            ? updatedCategories.filter((productCategory) => productCategory.id !== category.id)
            : [...updatedCategories, category];

        return {
          ...product,
          vendor_products_category: updatedCategories,
        };
      });

      if (isCategoryShared || isCategoryPartial) {
        setCategorySelected((prev) => prev.filter((selectedCategory) => selectedCategory.id !== category.id));
      } else {
        setCategorySelected((prev) => [...prev, category]);
      }

      return updatedProducts;
    });
  };

  const categoryNesting = (categories: CategoryInterface[]) => {
    const categoryMap: { [key: string]: CategoryInterface } = {};

    categories.forEach((category) => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    const tree: CategoryInterface[] = [];

    categories.forEach((category) => {
      if (category.parent === 0) {
        tree.push(categoryMap[category.id]);
      } else if (category.parent && categoryMap[category.parent]) {
        (categoryMap[category.parent].children as CategoryInterface[]).push(categoryMap[category.id]);
      }
    });
    return tree;
  };

  const categoryView = () => {
    const renderCategoryView = (category: CategoryInterface) => {
      const isCategoryShared = categoryShared.some((sharedCategory) => sharedCategory.id === category.id);
      const isCategoryPartial = categorySelected.some((selectedCategory) => selectedCategory.id === category.id) && !isCategoryShared;
      let categoryParent;

      if (categorySearchTerm) {
        categoryParent = webshopCategories.find((parent) => Number(parent.id) === category.parent);
      }
      let iconSrc = '';
      if (isCategoryShared) {
        iconSrc = '/fa/svgs/solid/check.svg';
      } else if (isCategoryPartial) {
        iconSrc = '/fa/svgs/solid/minus.svg';
      }
      return (
        <React.Fragment key={category.id}>
          <div className="edit-custom-checkbox">
            <label>
              <input type="checkbox" value={category.id} checked={isCategoryShared} onChange={() => handleCategorySelected(category)} />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                {iconSrc && <img src={iconSrc} loading="lazy" alt="check" />}
              </span>
            </label>
            <span className="edit-custom-checkbox-text">
              {category.name}
              {categoryParent && <span>{` /${categoryParent.name}`}</span>}
            </span>
          </div>
          {category.children && category.children.length > 0 && (
            <div className="child-categories ps-3">{category.children.map((childCategory) => renderCategoryView(childCategory))}</div>
          )}
        </React.Fragment>
      );
    };

    return <div>{categoryTree.map((category) => renderCategoryView(category))}</div>;
  };

  const CategoryOptions = () => {
    const renderCategoryOptions = (category: CategoryInterface, depth = 0) => {
      const prefix = '-'.repeat(depth);

      return (
        <React.Fragment key={category.id}>
          <option value={category.id}>
            {prefix}
            {category.name}
          </option>
          {category.children && category.children.length > 0 && category.children.map((childCategory) => renderCategoryOptions(childCategory, depth + 1))}
        </React.Fragment>
      );
    };

    return (
      <>
        <option value="">Alle kategorier</option>
        {categoryTree.map((category) => renderCategoryOptions(category))}
      </>
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCategorySearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategorySearchTerm(event.target.value);
  };

  const handleMarkAll = () => {
    if (!markAll) {
      setMarkAll(true);
      setSelectedProducts(editedProducts);
    } else {
      setMarkAll(false);
      setSelectedProducts([]);
    }
  };

  const activateToast = () => {
    setToastActive(true);
    setTimeout(() => {
      setToastActive(false);
    }, 4000);
  };

  const performMassChange = async () => {
    if (!selectedProducts.length) {
      return;
    }

    // Opdater kategorier
    const commonCategories = categorySelected.filter((category) =>
      selectedProducts.every(
        (product) =>
          Array.isArray(product.vendor_products_category) &&
          (product.vendor_products_category as CategoryInterface[]).some((productCategory) => productCategory.id === category.id),
      ),
    );

    const updatedProducts = selectedProducts.map((product) => {
      const updatedProduct = { ...product };

      const existingCategories: CategoryInterface[] = Array.isArray(updatedProduct.vendor_products_category)
        ? [...(updatedProduct.vendor_products_category as CategoryInterface[])]
        : [];

      const updatedCategories = [
        ...existingCategories,
        ...commonCategories.filter((commonCategory) => !existingCategories.some((existingCategory) => existingCategory.id === commonCategory.id)),
      ];

      updatedProduct.vendor_products_category = updatedCategories;

      // Opdater lager
      updatedProduct.vendor_products_stock = stock ? parseInt(stock, 10) : product.vendor_products_stock;

      if (product.product_variations && product.product_variations.length > 0) {
        updatedProduct.product_variations = product.product_variations.map((variation) => ({
          ...variation,
          vendor_product_variations_stock: stock ? parseInt(stock, 10) : variation.vendor_product_variations_stock,
        }));
      }

      // Opdater status
      updatedProduct.vendor_products_status = status ? status : product.vendor_products_status;

      // Opdater priser
      if (discountVejlType === 'ændring') {
        updatedProduct.vendor_products_price = vejlPrice ? parseFloat(vejlPrice) : product.vendor_products_price;
        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_price: vejlPrice ? parseFloat(vejlPrice) : variation.vendor_product_variations_price,
          }));
        }
      }

      if (discountType === 'ændring') {
        updatedProduct.vendor_products_sales_price = discountPrice ? parseFloat(discountPrice) : product.vendor_products_sales_price;
        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_sales_price: discountPrice ? parseFloat(discountPrice) : variation.vendor_product_variations_sales_price,
          }));
        }
      }

      if (discountCostType === 'ændring') {
        updatedProduct.vendor_products_cost_price = costPrice ? parseFloat(costPrice) : product.vendor_products_cost_price;
        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => ({
            ...variation,
            vendor_product_variations_cost_price: costPrice ? parseFloat(costPrice) : variation.vendor_product_variations_cost_price,
          }));
        }
      }

      if (discountVejlType === 'stigning') {
        const discountPercentage = discountVejl ? parseFloat(discountVejl) / 100 : 0;
        updatedProduct.vendor_products_price = product.vendor_products_price + product.vendor_products_price * discountPercentage;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            variation.vendor_product_variations_price =
              variation.vendor_product_variations_price + variation.vendor_product_variations_price * discountPercentage;
            return variation;
          });
        }
      }

      if (discountVejlType === 'prisfald') {
        const discountPercentage = discountVejl ? parseFloat(discountVejl) / 100 : 0;
        updatedProduct.vendor_products_price = product.vendor_products_price - product.vendor_products_price * discountPercentage;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            variation.vendor_product_variations_price =
              variation.vendor_product_variations_price - variation.vendor_product_variations_price * discountPercentage;
            return variation;
          });
        }
      }

      if (discountType === 'stigning' && product.vendor_products_sales_price) {
        const discountPercentage = discount ? parseFloat(discount) / 100 : 0;
        updatedProduct.vendor_products_sales_price = product.vendor_products_sales_price + product.vendor_products_sales_price * discountPercentage;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            if (variation.vendor_product_variations_sales_price) {
              variation.vendor_product_variations_sales_price =
                variation.vendor_product_variations_sales_price + variation.vendor_product_variations_sales_price * discountPercentage;
            }
            return variation;
          });
        }
      }

      if (discountType === 'prisfald' && product.vendor_products_sales_price) {
        const discountPercentage = discount ? parseFloat(discount) / 100 : 0;
        updatedProduct.vendor_products_sales_price = product.vendor_products_sales_price - product.vendor_products_sales_price * discountPercentage;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            if (variation.vendor_product_variations_sales_price) {
              variation.vendor_product_variations_sales_price =
                variation.vendor_product_variations_sales_price - variation.vendor_product_variations_sales_price * discountPercentage;
            }
            return variation;
          });
        }
      }

      if (discountCostType === 'stigning' && product.vendor_products_cost_price) {
        const discountPercentage = discountCost ? parseFloat(discountCost) / 100 : 0;
        updatedProduct.vendor_products_cost_price = product.vendor_products_cost_price + product.vendor_products_cost_price * discountPercentage;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            if (variation.vendor_product_variations_cost_price) {
              variation.vendor_product_variations_cost_price =
                variation.vendor_product_variations_cost_price + variation.vendor_product_variations_cost_price * discountPercentage;
            }
            return variation;
          });
        }
      }

      if (discountCostType === 'prisfald' && product.vendor_products_cost_price) {
        const discountPercentage = discountCost ? parseFloat(discountCost) / 100 : 0;
        updatedProduct.vendor_products_cost_price = product.vendor_products_cost_price - product.vendor_products_cost_price * discountPercentage;

        if (product.product_variations && product.product_variations.length > 0) {
          updatedProduct.product_variations = product.product_variations.map((variation) => {
            if (variation.vendor_product_variations_cost_price) {
              variation.vendor_product_variations_cost_price =
                variation.vendor_product_variations_cost_price - variation.vendor_product_variations_cost_price * discountPercentage;
            }
            return variation;
          });
        }
      }

      return updatedProduct;
    });
    setSelectedProducts(updatedProducts);

    // Opdatering af Dexie med de ændrede produkter
    for (const product of updatedProducts) {
      if (product.vendor_products_id !== undefined) {
        await dexieDB.editedProducts.update(product.vendor_products_id, product);
      }
    }

    setSelectedProducts(updatedProducts);
    setEditedCount(updatedProducts.length);
    setEditedProducts(updatedProducts);
    activateToast();
    clearAll();
  };

  function calculateTotalMarked() {
    let total = 0;

    selectedProducts.forEach((product) => {
      total += 1;

      if (product.product_variations) {
        total += product.product_variations.length;
      }
    });

    return total;
  }

  const handleShowMore = () => {
    setVisibleCount((count) => count + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(editedProductsCount ? editedProductsCount : 0);
  };

  const handleShowMassedit = () => {
    setMassedit((prev) => !prev);
  };

  const getPrevAndNextProduct = (selectedProduct: ProductInterface | null | undefined) => {
    if (!selectedProduct || editedProducts.length === 0) {
      return { prevProduct: null, nextProduct: null };
    }

    const productIndex = editedProducts.findIndex((product) => product.vendor_products_id === selectedProduct.vendor_products_id);

    if (productIndex === -1) {
      return { prevProduct: null, nextProduct: null };
    }

    const prevProduct = productIndex > 0 ? editedProducts[productIndex - 1] : null;
    const nextProduct = productIndex < editedProducts.length - 1 ? editedProducts[productIndex + 1] : null;

    return { prevProduct, nextProduct };
  };

  return (
    <>
      <div className="toast-container">
        <CustomToast
          show={toastActive}
          message={editedCount + (editedCount === 1 ? ' produkt' : ' produkter') + ' blev opdateret.'}
          onClose={() => setToastActive(false)}
          duration={3500}
          title={'Ændringerne er gemt.'}
          type={'toast-success'}
        />
      </div>

      <Row className="heading-intro">
        <Col>
          <h1>Produkter</h1>
        </Col>
      </Row>

      <span>{'Total af ' + calculateTotalMarked() + ' produkter er markeret.'}</span>

      <Row className="edit-options">
        <Col xs={4} className="p-0 pe-2">
          <Search placeholder={'Søg efter produkt'} value={searchTerm} onChangeFunction={handleSearchChange} />
        </Col>

        <Col xs={'auto'} className="p-0 pe-2">
          <select
            className="form-select edit-options-category"
            aria-label="Category filter"
            value={category}
            onChange={handleCategoryChange}
            disabled={isLoadingCategories}
          >
            {isLoadingCategories ? <option>Henter Kategorier...</option> : CategoryOptions()}
          </select>
        </Col>
        <Col className="p-0">
          <div>
            <Button onClick={handleShowMassedit}>Masseredigering</Button>
          </div>
        </Col>
      </Row>
      {massedit && (
        <Row className="mass-edit">
          <div>
            <div className="mass-edit-header">
              <p>Masseredigering</p>
              <Button className="mass-edit-close" variant="secondary" onClick={handleShowMassedit}>
                <img src="/fa/svgs/solid/xmark.svg" />
              </Button>
            </div>
            <Col className="d-flex">
              <div>
                <p className="mass-edit-subheader">
                  Kategorier
                  <button className="mass-edit-subheader-tooltip">
                    <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                    <span>Vælg de kategorier, produktet skal vises i på hjemmesiden.</span>
                  </button>
                </p>
                <div className="mass-edit-search">
                  <Search placeholder={'Søg efter kategori'} value={categorySearchTerm} onChangeFunction={handleCategorySearchChange}></Search>
                </div>
                <div className="mass-edit-checkboxes">
                  {isLoadingCategories ? (
                    <p>Loading</p>
                  ) : selectedProducts && selectedProducts.length ? (
                    categoryView()
                  ) : (
                    <p className="mass-edit-checkboxes-no-selected">*Vælg først et produkt</p>
                  )}
                </div>
              </div>
              <div className="mass-edit-options">
                <Row>
                  <Col xs={4}>
                    <p className="mass-edit-subheader">
                      Pris
                      <button className="mass-edit-subheader-tooltip">
                        <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                        <span>
                          Angiv produktets salgspris, som vises til kunden. Sæt én pris for valgte produkter, eller procentvis op/nedjuster priserne for de
                          valgte produkter.
                        </span>
                      </button>
                    </p>
                  </Col>
                  <Col>
                    <select className="form-select" aria-label="Price change" onChange={(e) => setDiscountVejlType(e.target.value)}>
                      <option value="">- Ingen ændring -</option>
                      <option value="ændring">Skift til:</option>
                      <option value="stigning">Prisstigning %</option>
                      <option value="prisfald">Prisfald %</option>
                    </select>
                    {discountVejlType === 'ændring' ? (
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        step={50}
                        placeholder={'Vejl. pris'}
                        value={vejlPrice}
                        onChange={(e) => setVejlPrice(e.target.value)}
                      />
                    ) : null}
                    {discountVejlType === 'stigning' || discountVejlType === 'prisfald' ? (
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        max={100}
                        placeholder={'Indtast ændring i %'}
                        value={discountVejl}
                        onChange={(e) => {
                          Number(e.target.value) <= 100 ? setDiscountVejl(e.target.value) : setDiscountVejl('');
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <p className="mass-edit-subheader">
                      Tilbud
                      <button className="mass-edit-subheader-tooltip">
                        <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                        <span>
                          Indstil en tilbudspris, der vises i stedet for normalprisen i en begrænset periode. Sæt én tilbudspris for valgte produkter, eller
                          procentvis op/nedjuster tilbudspriserne.
                        </span>
                      </button>
                    </p>
                  </Col>
                  <Col>
                    <select className="form-select" aria-label="Discount change" onChange={(e) => setDiscountType(e.target.value)}>
                      <option value="">- Ingen ændring -</option>
                      <option value="ændring">Skift til:</option>
                      <option value="stigning">Prisstigning %</option>
                      <option value="prisfald">Prisfald %</option>
                    </select>
                    {discountType === 'ændring' ? (
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        step={50}
                        placeholder={'Tilbudspris'}
                        value={discountPrice}
                        onChange={(e) => setDiscountPrice(e.target.value)}
                      />
                    ) : null}
                    {discountType === 'stigning' || discountType === 'prisfald' ? (
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        max={100}
                        placeholder={'Indtast ændring i %'}
                        value={discount}
                        onChange={(e) => {
                          Number(e.target.value) <= 100 ? setDiscount(e.target.value) : setDiscount('');
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <p className="mass-edit-subheader">
                      Kostpris
                      <button className="mass-edit-subheader-tooltip">
                        <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                        <span>
                          Indtast indkøbsprisen for at få overblik over produktets profitmargin. Sæt én kostpris for valgte produkter, eller procentvis
                          op/nedjuster kostpriserne.
                        </span>
                      </button>
                    </p>
                  </Col>
                  <Col>
                    <select className="form-select" aria-label="Cost change" onChange={(e) => setDiscountCostType(e.target.value)}>
                      <option value="">- Ingen ændring -</option>
                      <option value="ændring">Skift til:</option>
                      <option value="stigning">Prisstigning %</option>
                      <option value="prisfald">Prisfald %</option>
                    </select>
                    {discountCostType === 'ændring' ? (
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        step={50}
                        placeholder={'Kostpris'}
                        value={costPrice}
                        onChange={(e) => setCostPrice(e.target.value)}
                      />
                    ) : null}
                    {discountCostType === 'stigning' || discountCostType === 'prisfald' ? (
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        max={100}
                        placeholder={'Indtast ændring i %'}
                        value={discountCost}
                        onChange={(e) => {
                          Number(e.target.value) <= 100 ? setDiscountCost(e.target.value) : setDiscountCost('');
                        }}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <p className="mass-edit-subheader">
                      Status
                      <button className="mass-edit-subheader-tooltip">
                        <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                        <span>
                          Vælg produktets synlighed på webshoppen. &#39;Kladde&#39; skjuler produktet for kunderne, mens &#39;Udgivet&#39; gør det synligt.
                        </span>
                      </button>
                    </p>
                  </Col>
                  <Col>
                    <select className="form-select" aria-label="Status change" onChange={(e) => setStatus(e.target.value)}>
                      <option value="">- Ingen ændring -</option>
                      <option value="draft">Kladde</option>
                      <option value="publish">Udgiv</option>
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <p className="mass-edit-subheader">
                      Lagerantal
                      <button className="mass-edit-subheader-tooltip">
                        <img src="/fa/svgs/regular/question-circle.svg" loading="lazy" alt="tooltip" />
                        <span>Indtast det aktuelle antal enheder på lager, så lagerstatus vises korrekt for kunden.</span>
                      </button>
                    </p>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      placeholder={'Skriv antal'}
                      value={stock}
                      onChange={(e) => setStock(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </div>
          <div className="mt-2">
            <Button className="mass-edit-btn" variant="secondary" onClick={() => clearAll()}>
              Ryd alt
            </Button>
            <Button className="ms-2 mass-edit-btn" onClick={performMassChange} disabled={!selectedProducts || !selectedProducts.length}>
              Anvend
            </Button>
          </div>
        </Row>
      )}

      <Table className="mt-4 product-table">
        <thead>
          <tr className="mt-5 mb-4">
            <th className="text-center" onClick={handleMarkAll}>
              <div className="edit-custom-checkbox big-checkbox ms-1">
                <label>
                  <input type="checkbox" onClick={(event) => event.stopPropagation()} />
                  <span className="edit-custom-checkbox-check" aria-hidden="true">
                    <img className={markAll ? '' : 'hidden'} src="/fa/svgs/solid/check.svg" loading="lazy" alt="check" />
                  </span>
                </label>
              </div>
            </th>

            <th className="d-table-cell">
              <label>Billede</label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label className="sorting-label" onClick={() => handleSort('vendor_products_sku')}>
                Varenummer
                {sortField === 'vendor_products_sku' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_sku' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th>
              <label className="sorting-label" onClick={() => handleSort('vendor_products_name')}>
                Produktnavn
                {sortField === 'vendor_products_name' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_name' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label className="sorting-label" onClick={() => handleSort('vendor_products_category')}>
                Kategori
                {sortField === 'vendor_products_category' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_category' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th>
              <label className="sorting-label" onClick={() => handleSort('vendor_products_price')}>
                Pris
                {sortField === 'vendor_products_price' &&
                  (sortOrder === 'asc' ? (
                    <img src="/fa/svgs/solid/sort-up.svg" loading="lazy" alt="stigende" />
                  ) : (
                    <img src="/fa/svgs/solid/sort-down.svg" loading="lazy" alt="faldende" />
                  ))}
                {sortField !== 'vendor_products_price' && <img src="/fa/svgs/solid/sort.svg" loading="lazy" alt="sortering" />}
              </label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label>Status</label>
            </th>

            <th className="d-none d-xl-table-cell">
              <label>Lager</label>
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {editedProducts &&
            editedProducts.slice(0, visibleCount).map((product) => {
              if (product.product_variations && product.product_variations.length !== 0) {
                return (
                  <VariableEditProducts
                    key={product.vendor_products_id}
                    product={product}
                    selectedProducts={selectedProducts}
                    setIsSimpleModalOpen={setIsSimpleModalOpen}
                    setIsVariableModalOpen={setIsVariableModalOpen}
                    setProductUnderEdit={setProductUnderEdit}
                    setVariationId={setVariationId}
                    webshopCategories={webshopCategories}
                  />
                );
              } else {
                return (
                  <tr
                    className={selectedProducts.some((p) => p.vendor_products_id === product.vendor_products_id) ? 'product-table-selected' : ''}
                    key={product.vendor_products_id}
                  >
                    <SimpleEditProducts
                      key={product.vendor_products_id}
                      product={product}
                      selectedProducts={selectedProducts}
                      setIsSimpleModalOpen={setIsSimpleModalOpen}
                      setProductUnderEdit={setProductUnderEdit}
                      productUnderEdit={productUnderEdit}
                      webshopCategories={webshopCategories}
                    />
                  </tr>
                );
              }
            })}
        </tbody>
      </Table>
      <Row className="mb-1 mt-4">
        <Col>
          {visibleCount < (editedProductsCount ? editedProductsCount : 0) && (
            <>
              <Button onClick={handleShowMore} variant="primary">
                Vis mere
              </Button>
              <Button className="mx-2" onClick={handleShowAll} variant="primary">
                Vis alle ({editedProductsCount})
              </Button>
            </>
          )}
        </Col>
      </Row>

      <SimpleProductModal
        productUnderEdit={productUnderEdit}
        setProductUnderEdit={setProductUnderEdit}
        isSimpleModalOpen={isSimpleModalOpen}
        setIsSimpleModalOpen={setIsSimpleModalOpen}
        webshopCategories={webshopCategories}
        prevAndNext={getPrevAndNextProduct(productUnderEdit)}
      />
      <VariationProductModal
        productUnderEdit={productUnderEdit}
        isVariationModalOpen={isVariableModalOpen}
        setIsVariationModalOpen={setIsVariableModalOpen}
        variationId={variationId}
        setVariationId={setVariationId}
      />
    </>
  );
};
