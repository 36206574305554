import React, { useEffect, useState } from 'react';
// IndexedDb
import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from '../../shared/db';
//Models
import { ProductInterface } from '../../shared/types';
//Api
import { getVendorProducts } from 'Api';
//Styles
import { Col, Row, Button, Spinner, Table } from 'react-bootstrap';
//Components
import { SimpleProducts, VariableProducts } from 'components/products';
import Search from 'components/search';

export const SelectProductsPage: React.FC = () => {
  const [products, setProducts] = useState<ProductInterface[]>([]);
  const [sortField, setSortField] = useState<keyof ProductInterface | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [searchFilter, setSearchFilter] = useState('');
  const [currentPage] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [isAdding, setIsAdding] = useState(false);
  const [visibleCount, setVisibleCount] = useState<number>(20);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const selectedVendors = useLiveQuery(() => dexieDB.vendors.toArray());

  const selectedCategories = useLiveQuery(() => dexieDB.categories.toArray());

  const selectedProducts = useLiveQuery(() => dexieDB.selectedProducts.toArray());

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field as keyof ProductInterface);
      setSortOrder('asc');
    }

    localStorage.setItem('selectSortField', field);
    localStorage.setItem('selectSortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    if (!selectedVendors || selectedVendors.length === 0 || !selectedCategories || selectedCategories.length === 0) {
      return;
    }

    const getVendorProductsHandler = async (): Promise<void> => {
      const vendorIds: number[] = [];
      const categories: string[] = [];

      if (selectedVendors) {
        for await (const vendor of selectedVendors) {
          if (vendor.vendors_id) {
            vendorIds.push(vendor.vendors_id);
          }
        }
      }

      if (selectedCategories) {
        for await (const category of selectedCategories) {
          if (category.vendor_products_category) {
            categories.push(category.vendor_products_category);
          }
        }
      }

      if (searchFilter.length === 0 || searchFilter.length >= 3) {
        const response = await getVendorProducts(searchFilter.trim(), vendorIds, categories, currentPage, totalResults, sortOrder, sortField?.toString());

        if (response) {
          const fetchedProducts = [...response.data.results];

          setProducts(fetchedProducts);
          setTotalResults(response.data.total);
        }
      }
    };
    getVendorProductsHandler();
  }, [selectedVendors, selectedCategories, searchFilter, currentPage, totalResults, sortField, sortOrder]);

  useEffect(() => {
    const savedSortField = localStorage.getItem('selectSortField');
    const savedSortOrder = localStorage.getItem('selectSortOrder');

    if (savedSortField) {
      setSortField(savedSortField as keyof ProductInterface);
    }

    if (savedSortOrder) {
      setSortOrder(savedSortOrder as 'asc' | 'desc');
    }
  }, []);

  const handleSelectAll = async (): Promise<void> => {
    if (isAdding) return;
    setIsAdding(true);

    try {
      const allSelected = products.every((product) =>
        selectedProducts?.some((selectedProduct) => selectedProduct.vendor_products_id === product.vendor_products_id),
      );

      if (allSelected) {
        await dexieDB.selectedProducts.clear();
        await dexieDB.editedProducts.clear();
      } else {
        const bulkCreate: ProductInterface[] = products.map((product) => ({
          ...product,
          vendor_products_status: 'draft',
        }));

        await dexieDB.selectedProducts.bulkPut(bulkCreate);
        await dexieDB.editedProducts.bulkPut(bulkCreate);
      }
    } catch (error) {
      console.error('Error updating database:', error);
    } finally {
      setIsAdding(false);
    }
  };

  useEffect(() => {
    if (products.length === selectedProducts?.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [products, handleSelectAll]);

  const handleShowMore = () => {
    setVisibleCount((count) => count + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(totalResults);
  };

  function calculateTotalMarked() {
    let total = 0;

    if (selectedProducts) {
      selectedProducts.forEach((product) => {
        total += 1;

        if (product.product_variations) {
          total += product.product_variations.length;
        }
      });
    }

    return total;
  }

  return (
    <>
      <Row className="heading-intro">
        <Col>
          <h1>Produkter</h1>
          <h5>Vælg de produkter du vil importere</h5>
        </Col>
      </Row>

      <span>{'Total af ' + calculateTotalMarked() + ' produkter er markeret.'}</span>

      <Row className="mt-4">
        <Col sm={4}>
          <Search placeholder={'Søg efter produkt'} value={searchFilter} onChangeFunction={(e) => setSearchFilter(e.target.value)} />
        </Col>
      </Row>
      <Table className="mt-4 product-table">
        <thead>
          <tr className="mt-5 mb-4">
            <th>
              <div className="edit-custom-checkbox big-checkbox ms-1">
                <label>
                  <input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} />
                  <span className="edit-custom-checkbox-check" aria-hidden="true">
                    {isAdding ? (
                      <Spinner animation="border" role="status" size="sm"></Spinner>
                    ) : (
                      <img className={isAllSelected ? '' : 'hidden'} src="/fa/svgs/solid/check.svg" />
                    )}
                  </span>
                </label>
              </div>
            </th>
            <th className="d-table-cell">
              <label>Billede</label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label className="sorting-label" onClick={() => handleSort('vendor_products_sku')}>
                Varenummer
                {sortField === 'vendor_products_sku' &&
                  (sortOrder === 'asc' ? <img src="/fa/svgs/solid/sort-up.svg" /> : <img src="/fa/svgs/solid/sort-down.svg" />)}
                {sortField !== 'vendor_products_sku' && <img src="/fa/svgs/solid/sort.svg" />}
              </label>
            </th>

            <th className="d-table-cell">
              <label className="sorting-label" onClick={() => handleSort('vendor_products_name')}>
                Produktnavn
                {sortField === 'vendor_products_name' &&
                  (sortOrder === 'asc' ? <img src="/fa/svgs/solid/sort-up.svg" /> : <img src="/fa/svgs/solid/sort-down.svg" />)}
                {sortField !== 'vendor_products_name' && <img src="/fa/svgs/solid/sort.svg" />}
              </label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label className="sorting-label" onClick={() => handleSort('vendor_products_manufactor')}>
                Brand
                {sortField === 'vendor_products_manufactor' &&
                  (sortOrder === 'asc' ? <img src="/fa/svgs/solid/sort-up.svg" /> : <img src="/fa/svgs/solid/sort-down.svg" />)}
                {sortField !== 'vendor_products_manufactor' && <img src="/fa/svgs/solid/sort.svg" />}
              </label>
            </th>

            <th className="d-none d-sm-table-cell">
              <label className="sorting-label" onClick={() => handleSort('vendor_products_price')}>
                Pris
                {sortField === 'vendor_products_price' &&
                  (sortOrder === 'asc' ? <img src="/fa/svgs/solid/sort-up.svg" /> : <img src="/fa/svgs/solid/sort-down.svg" />)}
                {sortField !== 'vendor_products_price' && <img src="/fa/svgs/solid/sort.svg" />}
              </label>
            </th>
          </tr>
        </thead>

        <tbody>
          {products.slice(0, visibleCount).map((product) => {
            if (product.product_variations && product.product_variations.length !== 0) {
              return <VariableProducts key={product.vendor_products_id} product={product} selectedProducts={selectedProducts} />;
            } else {
              return <SimpleProducts key={product.vendor_products_id} product={product} selectedProducts={selectedProducts} />;
            }
          })}
        </tbody>
      </Table>
      <Row className="mb-1 mt-4">
        <Col>
          {visibleCount < products.length && (
            <>
              <Button onClick={handleShowMore} variant="primary">
                Vis mere
              </Button>
              <Button className="mx-2" onClick={handleShowAll} variant="primary">
                Vis alle ({totalResults})
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
