import React from 'react';
import { ProductContentProps } from '../types';

const ProductContent: React.FC<React.PropsWithChildren<ProductContentProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');

  return (
    <>
      <td className="d-table-cell product-item">
        {props.showButton && (
          <div className="edit-custom-checkbox ms-1">
            <label>
              <input type="checkbox" disabled readOnly />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                <img className={props.selected ? '' : 'hidden'} src="/fa/svgs/solid/check.svg" />
              </span>
            </label>
          </div>
        )}
      </td>
      <td className="product-item">
        {props.showImage === true && props.product.vendor_products_image && props.product.vendor_products_image.length !== 0 ? (
          <label>
            <img width={65} height={65} src={props.product.vendor_products_image} alt="produkt billede" loading="lazy" />
          </label>
        ) : null}
      </td>
      <td className="d-none d-sm-table-cell product-item">
        <div className="multi-line-wrapper">
          {props.showSku && <label>{props.product.vendor_products_sku}</label>}
          {props.showEan && <small>{props.product.vendor_products_ean}</small>}
        </div>
      </td>
      <td className="product-item">{props.showName && <label>{props.product.vendor_products_name}</label>}</td>
      <td className="d-none d-sm-table-cell product-item">{props.showManufactor && <label>{props.product.vendor_products_manufactor}</label>}</td>
      <td className="d-none d-sm-table-cell product-item">
        {props.showPrice && <label>{numberFormatter.format(props.product.vendor_products_price)} DKK</label>}
      </td>
    </>
  );
};

export default ProductContent;
